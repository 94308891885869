import { useContext } from 'react';
import { GenContext } from '../../gen-state/gen.context';
import { ethers } from "ethers";
import { useState } from "react";
import Abi from "./contractAbi.json";

// layout
import AppLayout from "../../layouts/AppLayout";
import { AbiItem } from "web3-utils";


//  Create WalletConnect Provider

// declare global {
//   interface Window {
//     ethereum?: any;
//     web3?:any
//   }
// }

const Mint = () => {
  const minterAddress = "0x45D9e8d6b30d38b77DEe14598F4486fd363c4016";
  const [quantity, setQuantity] = useState(0)
  const [disabled, setDisabled] = useState(true)
  const { account, connector, dispatch } = useContext(GenContext);

  const handleQuantity = (evt: any) => {
    evt.preventDefault();
    console.log(account, connector)
    try {
        let validatedAddress = evt.target.value
        setQuantity(validatedAddress);
        setDisabled(false)
    } catch (e: any) {
        setQuantity(evt.target.value)
        setDisabled(true)
    }
  }

  // async function run(){
  //   //  Create WalletConnect Provider
  //   const provider = new WalletConnectProvider({
  //     infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
  //   });

  //   //  Enable session (triggers QR Code modal)
  //   await provider.enable();
  // }

  

  async function faucet() {
    if (!connector) {
      alert("please connect your wallet");
      return;
    }
    const provider = new ethers.providers.Web3Provider(connector)
    const signer = provider.getSigner();
    console.log('faucet', provider, signer)

    if (connector.isWalletConnect) {
      if (connector.chainId !== 137) {
        alert("change network to polygon")
        return;
      }
      // const signer = connector.getSigner();
      // console.log('.....', signer)
      const contract = new ethers.Contract(minterAddress, Abi, signer);
      const pricePerToken = await contract.NFT_PRICE() / 10**18
      console.log('mint price', ethers.utils.parseEther((pricePerToken*quantity).toString()).toString())
      // const binge = Web3.utils.toBN(ethers.utils.parseEther((pricePerToken*quantity).toString()))
      console.log('aca', account)
      const ethNonce = await signer.getTransactionCount()
      console.log('NONCE', ethNonce)
      const tx: ethers.providers.TransactionRequest = {
        from: account,
        to: minterAddress,
        // gasLimit: ethers.utils.hexlify(250000),
        // gasPrice: ethers.utils.parseUnits('5', "gwei"),
        value: ethers.utils.parseEther((pricePerToken*quantity).toString()),
        data: contract.interface.encodeFunctionData("mint", [quantity]),
        nonce: ethNonce
      }
      console.log('tx', tx)
      try {
        console.log('abouta sign')
        const result = await signer.sendTransaction(tx)
        console.log(result)
        alert(`https://mumbai.polygonscan.com/tx/${result.hash}`)
      } catch (error) {
        console.log('error e', error)
        alert(error)
        return
      }
      return
    } else {
      // const signer = provider.eth.getAccounts;
      console.log('mta sign', signer)
        const contract = new ethers.Contract(minterAddress, Abi, signer);
        const pricePerToken = await contract.NFT_PRICE() / 10**18
        const network = await provider.getNetwork();
        if (network.chainId !== 137) {
          alert("change to polygon mainnet");
          return;
        }
        try {
          console.log(await contract.symbol(), quantity)
            const tx = await contract.mint(quantity, { value: ethers.utils.parseEther((pricePerToken*quantity).toString()) });
            await tx.wait()
            alert(`https://polygonscan.com/tx/${tx.hash}`)
        } catch (error: any) {
          console.log(error);
            if (error.code === 4001) {
                alert("You cancelled the transaction!")
            } else {
                alert(error.data.message)
            }
        }
    }
        
}

  return (
    <AppLayout showFooter={false}>
      <div className="Mint">
        <div className="Mint__inner">
          <h1>Internet Pictures Here 🔜</h1>
          <input type="number" min="0" onChange={handleQuantity} placeholder="enter amount to mint" style={{
              color: "#446396",
              padding: "2px",
              textAlign: "center",
              border: "2px solid",
              margin: "0px 0px 0px 0px",
              height: "50px"
            }} />
          <p 
          style={{
            margin: "15px 0 0px 0px"
          }}
          >
            <button 
            style={{
              color: "#446396",
              border: "1px solid",
              margin: "0px 0px 0px 0px"
            }}
            onClick={faucet}
            disabled={disabled}
            type="button"
            >
            Mint your Minority Drop. 
            </button>
          </p>
        </div>
      </div>
    </AppLayout>
  );
};

export default Mint;
