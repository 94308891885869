import Home from '../pages/Home';
import Story from '../pages/Story';
import Mint from '../pages/Mint';
import HER from '../pages/HER';

let PublicRoutes = [
	{
		path: "/story",
		name: "Story",
		privateroute: false,
		component: Story,
		show: false,
		id: "Story"
	},
	{
		path: "/mint",
		name: "Mint",
		privateroute: false,
		component: Mint,
		show: false,
		id: "mint"
	},
	{
		path: "/her",
		name: "her",
		privateroute: false,
		component: HER,
		show: false,
		id: "her"
	},
	{
		path: "/",
		name: "Home",
		privateroute: false,
		component: Home,
		show: false,
		id: "Home",
	}
]

export default PublicRoutes;