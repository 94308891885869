import React from "react";
import { Route, Routes } from "react-router-dom";

// pages
import NotFound from "../pages/NotFound";

// routes
import PublicRoutes from "./routes";

export interface CustomRouterProps {}

const CustomRouter = () => {
  return (
    <Routes>
      {PublicRoutes.map((prop, key) => {
        return (
          <Route path={prop.path} key={key} element={<prop.component />} />
        );
      })}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default CustomRouter;
