import genActionTypes from './gen.types';

export const setConnector = connector => ({
  type: genActionTypes.SET_CONNECTOR,
  payload: connector
})

export const setAccount = account => ({
  type: genActionTypes.SET_ACCOUNT,
  payload: account
})

