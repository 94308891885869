import genActionTypes from './gen.types';

export const INITIAL_STATE = {
  account: '',
  connector: null,
}

export const genReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case genActionTypes.SET_ACCOUNT:
      return {
        ...state,
        account: action.payload
      }
    case genActionTypes.SET_CONNECTOR:
      return {
        ...state,
        connector: action.payload
      }
    default:
      return state;
  }
}