import React from "react";

// layout
import AppLayout from "../../layouts/AppLayout";

const Story = () => {
  return (
    <AppLayout showFooter={false}>
      <div className="Story">
        <div className="Story__inner">
          <h1>✏️ Still doodling, will be here soon 🔜</h1>
          <p>
            Keep 🔔 up to date with our community by checking out our LinkTree</p>
          <div className="Story__social-links">
            <a
              href="https://discord.gg/4vdtmQqz6d"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="Minority Programmers Discord" src="/icons/discord.svg" />
            </a>
            <a
              href="https://twitter.com/minorityprogram"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="Minority Programmers Twitter" src="/icons/twitter.svg" />
            </a>
            <a
              href="https://www.instagram.com/minorityprogrammers"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="Minority Programmers Instagram" src="/icons/instagram.svg" />
            </a>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default Story;
