import React from "react";

// components
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

interface AppLayoutProps {
  children: React.ReactNode;
  showFooter?: boolean;
  showNavbar?: boolean;
}

const AppLayout = ({
  children,
  showFooter = true,
  showNavbar = true,
}: AppLayoutProps) => {
  return (
    <div className="AppLayout">
      {showNavbar && <Navbar />}
      <div className="AppLayout__body">{children}</div>
      {showFooter && <Footer />}
    </div>
  );
};

export default AppLayout;
