
import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import PublicRouter from "./routers/PublicRouter";
import DotRing from "./components/ringAnimations";

function App() {
  return (
    <div>
      <Suspense
        fallback={
          <div
            className="h-full flex justify-center items-center"
            style={{ height: "100vh" }}
          >
            <p>Minority Programmers NFT</p>
          </div>
        }
      >
        <Router>
          <div className="hidden lg:block">
            <DotRing />
          </div>{" "}
          <PublicRouter />
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
