import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/main.scss';
import reportWebVitals from './reportWebVitals';
import MouseContextProvider from "./context/mouse-context";
import GenContextProvider from './gen-state/gen.context';


ReactDOM.render(
  <React.StrictMode>
     <MouseContextProvider>
       <GenContextProvider>
       <App />
       </GenContextProvider>
    </MouseContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();


