import React from "react";

interface FooterProps {}

const Footer = (props: FooterProps): JSX.Element => {
  return (
    <div className="Footer">
      <div className="Footer__inner">
        <div className="Footer__social-links">
          <a
            href="https://discord.gg/4vdtmQqz6d"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="Minority Programmers Discord" src="/icons/discord.svg" />
          </a>
          <a
            href="https://twitter.com/minorityprogram"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="Minority Programmers Twitter" src="/icons/twitter.svg" />
          </a>
          <a
            href="https://www.instagram.com/minorityprogrammers"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="Minority Programmers Instagram Icon" src="/icons/instagram.svg" />
          </a>
          <a
            href="https://linktr.ee/MinorityProgrammers"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="Minority Programmers Linktree" src="/icons/globe.svg" />
          </a>
        </div>
        <div className="Footer__content">
          <img src="/icons/polygon.svg" alt="Powered by Polygon" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
