import React from "react";

const NotFound = () => {
  return (
    <div className="NotFound">
      <div className="NotFound__inner">
        <div className="NotFound__image">
          <img src="/img/droppreview.gif" alt="Lined Paper" />
        </div>
        <h2>✏️ Still doodling, will be here soon 🔜</h2>
        <p>
        Keep 🔔 up to date with our community by checking out our LinkTree
        </p>
        <a href="/">
          <button>Go to homepage</button>
        </a>
      </div>
    </div>
  );
};

export default NotFound;
